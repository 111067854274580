import { ACTION_NAMES, getEnforcerEnrich } from '../../actions/actions';
import { extractErrMsg } from '../../utils/utils';

const initialState = {};

export const EnforcerEnrichmentRedirect = () => dispatch => {
  dispatch({ type: ACTION_NAMES.ENFORCER_START });
  const queryParams = window.location.search;
  getEnforcerEnrich(queryParams).then(res => {
    if (res.status === 202) {
      dispatch({
        type: ACTION_NAMES.ENFORCER_REDIRECT_SUCCESS,
        response: res.data,
      });
    }
    if (res.status === 200) {
      dispatch({
        type: ACTION_NAMES.ENFORCER_SAML_SUCCESS,
        response: res.data,
      });
    }
    if (res.status >= 400) {
      const errorMessage = extractErrMsg(res, true);
      const error = res?.data || {};
      dispatch({
        type: ACTION_NAMES.ENFORCER_FAIL,
        error,
        errorMessage,
      });
    }
  }).catch(res => {
    const errorMessage = extractErrMsg(res, true);
    const error = res?.response?.data || {};
    dispatch({
      type: ACTION_NAMES.ENFORCER_FAIL,
      error,
      errorMessage,
    });
  });
};

export default function EnforcerPageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_NAMES.ENFORCER_REDIRECT_SUCCESS:
      return { ...state, redirectUrl: action.response.redirect_url };

    case ACTION_NAMES.ENFORCER_SAML_SUCCESS:
      return { ...state, samlData: action.response };
    case ACTION_NAMES.ENFORCER_FAIL:
      return { ...state,
        error: action.error,
        errorMessage: action.errorMessage };
    default:
      return { ...state };
  }
}
